<template>
  <ValidationProvider v-slot="{ errors, valid }" :vid="vid" :name="$attrs.name || $attrs.label" :rules="rules">
    <ControlField :horizontal="horizontal" v-bind="$attrs" :type="{ 'is-danger': errors[0] }" :custom-class="verified ? 'is-verified' : ''">
      <DynamicContact
        :class="{ 'is-danger': errors[0] }"
        :value="innerValue"
        @input="onInput"
        :vid="vid"
        :config="config"
        :horizontal="horizontal"
        :description="description"
        :field="field"
      />
    </ControlField>
    <p class="help is-danger">{{ errors[0] }}</p>
  </ValidationProvider>
</template>
<script>

export default {
  components: {},
  props: {
    horizontal: {
      type: Boolean,
      default: false
    },
    vid: {
      type: String,
      default: ''
    },
    field: {
      type: Object
    },
    config: {
      type: Object
    },
    rules: {
      type: [Object, String],
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    verified: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object
    }
  },
  data() {
    return {
      innerValue: this.value
    };
  },
  watch: {
    value(val) {
      this.innerValue = val;
    }
  },
  methods: {
    onInput(val) {
      this.$emit('input', val);
    }
  }
};
</script>
