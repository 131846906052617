<template>
  <ValidationProvider v-slot="{ errors, valid }" :name="$attrs.name || $attrs.label" :rules="''" class="validated-select" >
    <ControlField grouped :horizontal="horizontal" v-bind="$attrs" :type="{ 'is-danger': errors[0] }">
      <r-autocomplete
        ref="autocomplete"
        expanded
        :openOnFocus="!!searchValue"
        :value="searchValue"
        @input="searchValue = $event"
        :placeholder="$t('contact.contact')"
        :data="contacts"
        :custom-formatter="formatter"
        :loading="isFetching"
        @typing="getAsyncData"
        @select="onSelect"
        :disabled="$attrs.verified"
      >
        <template slot="empty">{{ $t('common.noResultsFound') }}</template>
        <template slot="footer"><button class="button is-primary is-small" @click="addContact">{{ $t('contact.new') }}</button></template>
      </r-autocomplete>
      <button v-if="isMounted && !expanded && searchValue" class="button is-primary" @click="focus">{{ $t('common.search') }}</button>
<!--      <button v-if="searchValue" class="button is-primary" @click="addContact">Create new</button>-->
    </ControlField>
  </ValidationProvider>
</template>

<script>
import ContactService from '@/services/ContactService';
import CreateContactDialog from './CreateContactDialog.vue';
import { formatPersonNumber, formatVatNumber } from '@/utils/formatVatNumber';

export default {
  name: 'ContactSearch',
  props: {
    value: {
      type: Object
    },
    type: {
      type: String
    },
    tenant_id: {
      type: String
    },
    horizontal: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isMounted: false,
      searchValue: '',
      isFetching: false,
      contacts: []
    };
  },

  computed: {
    expanded() {
      return !!this.$refs.autocomplete.isActive;
    }
  },

  created() {
    if (this.value?.vat_number) {
      this.searchValue = this.value.vat_number;
    } else
    if (this.value?.name) {
      this.searchValue = this.value.name;
    }
  },

  mounted() {
    this.isMounted = true;
    this.getAsyncData(this.searchValue);
  },

  methods: {
    focus() {
      if (this.$refs.autocomplete) {
        this.getAsyncData(this.searchValue);
        this.$refs.autocomplete.focused();
      }
    },
    onSelect(contact) {
      this.$emit('update:selectedItems', [ contact ]);
    },
    clearSelection() {
      this.search = null;
      this.$emit('update:selectedItems', []);
    },
    async getAsyncData(searchValue) {
      if (!searchValue.length) {
        this.contacts = [];
        return;
      }
      this.isFetching = true;

      const params = {
        '~search': searchValue,
        _orderBy: 'name',
        tenant_id: this.tenant_id,
        type: this.type || undefined,
        _pageSize: 20
      };

      const response = await ContactService.getAll(params);
      this.contacts = response.data.data;
      this.isFetching = false;
    },
    formatter(contact) {
      const address = (contact.address || '') + ' ' + (contact.address_second || '') + ', ' + (contact.postal_code || '') + ' ' + (contact.city || '');
      let number = '';
      if (contact.vat_number) {
        switch (contact.type) {
          case 'PERSON':
            number = `[${formatPersonNumber(contact.vat_number)}]`;
            break;
          case 'COMPANY':
            number = `[${formatVatNumber(contact.vat_number, true)}]`;
            break;
        }
      }
      return contact.name + ' ' + number + (address.length > 4 ? ', ' + address : '');
    },
    addContact() {
      const value = {
        type: this.type || '',
        data: {
          name: this.searchValue
        }
      };
      this.$buefy.modal.open({
        parent: this,
        component: CreateContactDialog,
        hasModalCard: true,
        props: {
          type: this.type,
          tenant_id: this.tenant_id,
          value
        },
        events: {
          submit: contact => {
            this.onSelect(contact);
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.search-results {
  margin-bottom: 20px;
}
.search-input {
  margin-bottom: 20px;
}
.search-result {
  background-color: #ffffff;
  padding: 20px;

  & p {
    margin: 0;
  }

  .buttons {
    margin-top: 10px;

    & .button i {
      margin-right: 6px;
    }
  }
}

.flex-1 {
  flex: 1 1 auto;
}
.height-auto {
  height: auto;
}
</style>
