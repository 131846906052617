<template>
  <div id="app" :class="'page-' + $route.name">
    <LangNavBar class="lang-nav-bar--right-top" v-if="hasLangNavBar" />
    <router-view />
  </div>
</template>

<script>
import LangNavBar from '@/layout/LangNavBar.vue';
export default {
  name: 'App',
  components: { LangNavBar },
  computed: {
    hasLangNavBar() {
      return this.$route.meta.lang_navbar;
    }
  },
  watch: {
    $route() {
      this.handleLayout();
    }
  },
  created() {
    this.handleLayout();
  },
  methods: {
    handleLayout() {
      if (this.$route.meta.fullScreen) {
        document.body.classList.remove('has-navbar-fixed-top-temp');
        document.body.classList.add('is-fullscreen');
      } else {
        document.body.classList.add('has-navbar-fixed-top-temp');
        document.body.classList.remove('is-fullscreen');
      }
    }
  }
};
</script>
