<template>
  <ValidationProvider v-slot="{ errors, valid }" :vid="vid" :name="$attrs.name || $attrs.label">
    <ControlField v-bind="$attrs" :type="{ 'is-danger': errors[0] }" :custom-class="verified ? 'is-verified' : ''">
      <ul v-if="internalValue" class="list">
        <li v-for="itemIdx in arrLen" :key="itemIdx">
          <FormField
            :config="config"
            :locale="config.language"
            :field="itemField"
            :path="path + '[' + (itemIdx - 1) + ']'"
            :vpath="vpath + '[' + (itemIdx - 1) + ']'"
            :value="internalValue"
            @input="onInput(itemIdx - 1, $event)"
          />
        </li>
      </ul>
      <button type="button" @click="addItem" class="button is-small ml-5" >Add</button>
    </ControlField>
    <p class="help is-danger">{{ errors[0] }}</p>
  </ValidationProvider>
</template>
<script>
import FormField from '@/modules/dynamic-form/components/FormField.vue';
import expressions from 'angular-expressions';

export default {
  components: {
    FormField
  },
  props: {
    config: {
      type: Object,
      required: true
    },
    path: {
      type: String,
      required: true
    },
    field: Object,
    vid: {
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: () => ({})
    },
    verified: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      internalValue: [],
      arrLen: 0
    }
  },
  computed: {
    itemField() {
      return Object.assign({}, this.field, {
        label: null,
        is_array: false
      });
    }
  },
  async created() {
    this.setInternalValue(this.value);
  },
  watch: {
    async value(newVal) {
      this.setInternalValue(newVal);
    }
  },
  methods: {
    setInternalValue(newVal) {
      this.internalValue = JSON.parse(JSON.stringify(newVal)) || [];
      const expr = expressions.compile(this.path);
      const val = expr(this.internalValue) || [];
      this.arrLen = val.length;
    },
    onSubmit() {
    },
    addItem() {
      const expr = expressions.compile(this.path);
      const value = expr(this.internalValue) || [];
      value.push(undefined);
      this.$emit('update-with-path', {
        path: this.path,
        value: value
      });
    },
    onInput(itemIdx, value) {
      this.$emit('update-with-path', {
        path: `${this.path}[${itemIdx}]`,
        value: value
      });
    },
  }
}
</script>
