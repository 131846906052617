<template>
  <ValidationProvider v-slot="{ errors, valid }" :vid="vid" :name="$attrs.name || $attrs.label" :rules="rules" ref="validator">
    <slot></slot>
    <input v-model="innerValue" v-show="false" />
    <div class="field" :class="{ 'is-danger': errors[0] }">
      <div :class="{ 'field-body': config.horizontal, 'field-body-table-horizontal': config.horizontal }">
        <p class="help is-danger">{{ tableErrors(errors) }}</p>
        <div class="field">
          <div class="dynamic-data-table view-table page-filtered">
            <o-table :data="innerValue" class="o-table" :class="(config.readonly || !innerValue.length) ? 'readonly' : ''" :row-class="(row) => Boolean(verified[subVerificationPath(row)]) ? 'verified-row' : ''">
              <o-table-column v-slot="props" :label="$t('common.verified')" width="0" :th-attrs="() => ({ 'class': 'verified-header' })" :td-attrs="() => ({ 'class': 'verified-cell' })" v-if="config.allowVerification || config.alwaysShowLock" @click.stop.native>
                <LockItem :locked="Boolean(verified[subVerificationPath(props.row)]) || config.alwaysShowLock" @toggle="toggleVerified(props.row)" />
              </o-table-column>
              <o-table-column v-slot="props" v-for="(c, index) in collectionChildren" :key="index" :field="c.name" :label="getLocalizedField(c).label">
                <DynamicDataValueRenderer
                  :config="innerConfig"
                  :initial-value="initialValue"
                  :path="path + '['+ props.index +'].' + c.name"
                  :vpath="subVerificationPath(props.row) + '.' + c.name"
                  :verified="verified"
                  :field="c"
                  :type="c.type"
                  :value="value"
                  @verify="$emit('verify', $event)"
                  @update-with-path="$emit('update-with-path', $event)"
                  @click.native.stop.prevent />
                <div v-for="(err, idx) in cellErrors(errors, c.name, props.index)" :key="idx">
                  <p class="help is-danger">{{ err.msg }}</p>
                  <button class="button is-danger is-small" v-if="err.dialog" @click="openDialog(err.dialog)">{{ err.dialog.button }}</button>
                </div>
              </o-table-column>
              <o-table-column v-slot="props" v-if="collection.visibility !== 'READ_ONLY'" :td-attrs="() => ({ 'class': 'actions' })">
                <a @click.prevent.stop="openEditRowDialog(props.row, props.index)" v-if="!fieldDisabled(props.row)">
                  <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/fa/far/pencil.svg')" data-tst-btn="edit" />
                </a>
                <a @click.prevent.stop="openConfirmDeleteDialog(props.row, props.index)" v-if="!fieldDisabled(props.row)">
                  <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/fa/far/trash.svg')" data-tst-btn="delete" />
                </a>
              </o-table-column>
              <template slot="empty">
                <div class="has-text-grey">
                  {{ $t('common.noDataYet') }}
                </div>
              </template>
              <template slot="footer">
              </template>
            </o-table>
          </div>
          <div class="is-flex is-justify-content-space-between mt-1">
            <div>
              <a v-if="collection.visibility !== 'READ_ONLY' && !disabled && !config.readonly" @click="openAddRowDialog">
                <span class="fas fa-plus"/>
                {{ $t('common.addRow')}}
              </a>
            </div>
            <div>
              <a v-if="!config.readonly && !disabled" @click.prevent.stop="openConfirmDeleteAllDialog()">
                <span class="fas fa-trash"/>
                {{ $t('common.deleteAll')}}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import DynamicDataValueRenderer from '@/modules/dynamic-form/components/DynamicDataValueRenderer.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import { v4 as uuidv4 } from 'uuid';
import Vue from 'vue';
import expressions from 'angular-expressions';
import { mapGetters } from 'vuex';
import LockItem from "@/modules/dynamic-form/components/LockItem.vue";
export default {
  name: 'DynamicTable',

  components: {
    LockItem,
    DynamicDataValueRenderer
  },

  props: {
    vid: {
      type: String
    },
    rules: {
      type: [Object, String],
      default: ''
    },
    config: {
      type: Object,
      required: true
    },
    initialValue: {
      type: Object,
      default: null
    },
    path: {
      type: String,
      required: true
    },
    vpath: {
      type: String,
      required: true
    },
    collection: {
      type: Object,
      default: () => {}
    },
    // must be included in props
    value: {
      type: null,
      default: null
    },
    uploadSubDir: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    verified: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    innerValue: []
  }),

  computed: {
    field() {
      return this.collection;
    },
    innerConfig() {
      return Object.assign({}, this.config, {
        readonly: true
      })
    },
    ...mapGetters({
      user: 'authentication/user'
    }),
    collectionChildren() {
      if (!Array.isArray(this.collection.children)) {
        return [];
      }
      return this.collection.children
        .filter(child => child.type !== 'CALC');
    },
    localisedField() {
      const locale = this.config.language;
      if (!locale || locale === 'en') {
        return this.field;
      } else {
        const translations = this.field.translations || [];
        const translation = translations.find(translation => {
          return translation.lang === locale;
        });

        return translation || { label: this.field.label };
      }
    }
  },

  watch: {
    value(newVal) {
      const evaluate = expressions.compile(this.path);
      this.innerValue = evaluate(newVal) || [];
    }
  },

  created() {
    if (this.value) {
      const evaluate = expressions.compile(this.path);
      this.innerValue = evaluate(this.value) || [];
    }
  },

  methods: {
    getLocalizedField(field) {
      const locale = this.config.language;
      if (!locale || locale === 'en') {
        return field;
      } else {
        const translations = field.translations || [];
        const translation = translations.find(translation => {
          return translation.lang === locale;
        });

        return translation || { label: field.label };
      }
    },
    openAddRowDialog() {
      if (this.config.readonly) return true;
      if (this.disabled) return;

      const DynamicTableRowDialog = Vue.component(this.collection.add_row_dialog || 'DynamicTableRowDialog');
      this.$buefy.modal.open({
        parent: this,
        component: DynamicTableRowDialog,
        hasModalCard: true,
        props: {
          config: Object.assign({}, this.config, { allowVerification: false, tenant_id: this.config.tenant_id }),
          editMode: false,
          fields: this.collection.children,
          uploadSubDir: this.uploadSubDir
        },
        events: {
          input: async (obj) => {
            if (Array.isArray(obj.rows)) {
              for (const row of obj.rows) {
                this.innerValue.push({ id: uuidv4(), ...row });
              }
            } else {
              const row = obj.row;
              this.innerValue.push({ id: uuidv4(), ...row });
            }

            this.$emit('update-with-path', {
              path: this.path,
              value: this.innerValue
            });
          }
        }
      });
    },
    openEditRowDialog(row, index) {
      if (this.config.readonly) return true;
      if (this.disabled) return;

      const innerVerified = {};
      const rowVPath = this.subVerificationPath(row) + '.';
      for (const k in this.verified) {
        if (k.startsWith(rowVPath)) {
          innerVerified[k.substr(rowVPath.length)] = this.verified[k];
        }
      }

      const DynamicTableRowDialog = Vue.component(this.collection.edit_row_dialog || 'DynamicTableRowDialog');
      this.$buefy.modal.open({
        parent: this,
        component: DynamicTableRowDialog,
        hasModalCard: true,
        props: {
          config: this.config,
          vpath: this.subVerificationPath(row),
          verified: innerVerified,
          editMode: true,
          value: row,
          fields: this.collection.children,
          disabled: this.fieldDisabled(row),
          uploadSubDir: this.uploadSubDir,
        },
        events: {
          input: async ({ row, verified }) => {
            this.innerValue[index] = row;
            this.$emit('update-with-path', {
              path: this.path,
              value: this.innerValue
            });

            const arr = [];
            for (const k in verified) {
              const vpath = this.subVerificationPath(row) + '.' + k;
              arr.push({ vpath, value: verified[k] });
            }
            this.$emit('verify', arr);
          }
        }
      });
    },
    openConfirmDeleteAllDialog() {
      if (this.config.readonly) return true;
      if (this.disabled) return;

      this.$buefy.modal.open({
        parent: this,
        component: ConfirmDialog,
        hasModalCard: true,
        props: {
          title: this.$t('dynamicData.confirmDeleteAllRows'),
          message: this.$t('dynamicData.confirmDeleteAllRowsDesc'),
          onOk: async () => {
            this.innerValue.splice(0, this.innerValue.length);
            this.$emit('update-with-path', {
              path: this.path,
              value: this.innerValue
            });
          }
        }
      });
    },
    openConfirmDeleteDialog(row, index) {
      if (this.config.readonly) return true;
      if (this.disabled) return;

      this.$buefy.modal.open({
        parent: this,
        component: ConfirmDialog,
        hasModalCard: true,
        props: {
          title: this.$t('common.confirmDelete'),
          message: this.$t('common.confirmDeleteRowDesc', { text: row.function }),
          onOk: async () => {
            this.innerValue.splice(index, 1);
            this.$emit('update-with-path', {
              path: this.path,
              value: this.innerValue
            });
          }
        }
      });
    },
    fieldDisabled(entity) {
      if (this.config.readonly) return true;
      if (this.disabled) return true;

      return !!this.verified[this.subVerificationPath(entity)];
    },
    toggleVerified(row) {
      const value = !this.verified[this.subVerificationPath(row)] ? { validated_at: new Date(), validated_by: this.user.email } : undefined;
      this.$emit('verify', { vpath: this.subVerificationPath(row), value });
    },
    subVerificationPath(row) {
      return this.vpath + '[' +row.id + ']';
    },
    tableErrors(errors) {
      errors = errors.map(err => err.startsWith('{') ? JSON.parse(err) : err);
      errors = errors.filter(err => !err.cell);
      return errors[0];
    },
    cellErrors(errors, col, index) {
      errors = errors.map(err => err.startsWith('{') ? JSON.parse(err) : err);
      errors = errors = errors
        .filter(err => {
          return err.cell?.name === col && err.cell?.index === index
        });
      return errors;
    },
    openDialog(data) {
      if (data.dialog !== 'BoardAddRowDialog') throw new Error('Not implemented');

      const DynamicTableRowDialog = Vue.component(data.dialog);
      this.$oruga.modal.open({
        parent: this,
        component: DynamicTableRowDialog,
        hasModalCard: true,
        props: Object.assign({
          config: Object.assign({}, this.config, { allowVerification: false }),
          editMode: false,
          fields: this.collection.children,
          uploadSubDir: this.uploadSubDir
        }, data.props),
        events: {
          input: async (obj) => {
            if (Array.isArray(obj.rows)) {
              for (const row of obj.rows) {
                if (this.innerValue.find(item => item.type === row.type && item.enterprise?.id === row.enterprise?.id && item.person?.id === row.person?.id)) {
                  continue;
                }
                this.innerValue.push({ id: uuidv4(), ...row });
              }
            } else {
              const row = obj.row;
              this.innerValue.push({ id: uuidv4(), ...row });
            }

            await this.$emit('update-with-path', {
              path: this.path,
              value: this.innerValue
            });

            await this.$refs.validator.validate();
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.dynamic-data-table {
  background: #fff;
  border: 1px solid $grayscale-3;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;

  > h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
  }
}

.table-toolbar {
  margin: 0 14px;
}
</style>
