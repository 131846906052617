<template>
  <b-field class="control-field" v-bind="$attrs" :data-tst-field="name"><slot></slot></b-field>
</template>
<script>
export default {
  props: {
    name: String
  }
}
</script>
