<template>
  <select class="lang-selector" :value="currentLang" @input="changeLang($event.target.value)">
    <option v-for="lang in langs" :key="lang.code" :value="lang.code">{{ lang.code }}</option>
  </select>
</template>

<script>
import { mapActions } from 'vuex';
import Enums from '@/utils/enums';

export default {
  data() {
    const langs = [];

    for (let langCode in Enums.LanguageMap) {
      langs.push({
        code: langCode,
        name: Enums.LanguageMap[langCode]
      });
    }

    return {
      langs
    };
  },
  computed: {
    currentLang() {
      return this.$store.state.i18n.lang;
    }
  },
  methods: {
    ...mapActions({
      changeLang: 'i18n/changeLang'
    })
  }
};
</script>
