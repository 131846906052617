<template>
  <div class="modal-card" style="width: 600px">
    <header class="modal-card-head">
      <p v-if="!editMode" class="modal-card-title">{{ $t('common.addRow') }}</p>
      <p v-if="editMode" class="modal-card-title">{{ $t('common.editRow') }}</p>
    </header>
    <section class="modal-card-body">

      <ValidationObserver ref="observer" v-slot="{ invalid }" @submit.prevent="() => {}">
        <ValidationProvider v-slot="{ errors, valid }" :rules="getRules(contactField)" :name="contactField.label" vid="contact" slim>
          <ControlField :label="contactField.label" :type="{ 'is-danger': errors[0] }" :message="errors">
            <DynamicContact
              :value="internalValue.contact"
              :config="config"
              :horizontal="config.horizontal"
              :rules="getRules(contactField)"
              :description="contactField.description"
              :field="{ contact_type: null }"
              @input="onContact"
            />
          </ControlField>
        </ValidationProvider>

        <ValidatedSelect
          :value="internalValue.signee ? internalValue.signee.id : undefined"
          @input="setSignee"
          :label="signeeField.label"
          :placeholder="signeeField.label"
          :options="board"
          :reduce="item => item.id"
          :rules="getRules(signeeField)"
          :getOptionLabel="item => getSigneeName(item)"
        />

        <ValidatedInput
          v-model="internalValue.shares"
          :horizontal="config.horizontal"
          :rules="getRules(sharesField)"
          :label="sharesField.label"
          :placeholder="sharesField.label"
          :vid="sharesField.name"
          :message="sharesField.description"
          type="number"
        />

        <slot name="buttons">
          <div style="margin-top:1em; text-align:right;">
            <div class="buttons is-pulled-right">
              <button type="button" class="button is-outlined is-primary" @click="onCancel">{{ $t('form.cancel') }}</button>
              <button type="button" class="button is-primary" @click="forwardSubmit" v-if="!config.disabled && !config.readonly">{{ $t('form.save') }}</button>
            </div>
          </div>
        </slot>
      </ValidationObserver>

    </section>
  </div>
</template>

<script>
import DynamicContact from '@/modules/dynamic-form/components/DynamicContact.vue';
import ValidatedInput from '@/components/form/ValidatedInput.vue';
import ValidatedSelect from "@/components/form/ValidatedSelect.vue";
import ContactService from "@/services/ContactService";
import {formatPersonNumber, formatVatNumber} from "@/utils/formatVatNumber";

export default {
  name: 'ShareholderRowDialog',
  components: {
    DynamicContact, ValidatedInput, ValidatedSelect
  },
  props: {
    vpath: {
      type: String,
      default: ''
    },
    config: {
      type: Object,
      required: true
    },
    editMode: {
      type: Boolean,
      default: false
    },
    fields: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: null
    },
    verified: {
      type: Object,
      default: null
    },
    onSubmit: {
      type: Function,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      internalVerified: {},
      internalValue: {},
      board: []
    }
  },

  computed: {
    locale() {
      return this.config.language;
    },
    contactField() {
      const field = this.fields.find(f => f.name === 'contact');
      return this.localizeField(field);
    },
    sharesField() {
      const field = this.fields.find(f => f.name === 'shares');
      return this.localizeField(field);
    },
    signeeField() {
      const field = this.fields.find(f => f.name === 'signee');
      return this.localizeField(field);
    }
  },

  async created() {
    if (this.value) {
      this.internalValue = Object.assign({}, this.value);
    }
    if (this.verified) {
      this.internalVerified = this.verified;
    }
    await this.fetchBoard();
  },

  methods: {
    async fetchBoard() {
      this.board = [];
      if (this.internalValue.contact?.id) {
        try {
          const response = await ContactService.getOne(this.internalValue.contact.id);
          const contact = response.data;

          if (contact.type === 'COMPANY') {
            this.board = (contact.data?.board || [])
              .filter(row => !!row.person)
              .map(row => ({ ...row, id: row.enterprise?.id + ':' + row.person.id }));
          }
          if (contact.type === 'PERSON') {
            this.board = [
              {
                id: 'undefined:' + contact.id,
                type: '10003',
                person: {
                  id: contact.id,
                  name: contact.name,
                  firstname: contact.firstname,
                  lastname: contact.lastname,
                  vat_number: contact.vat_number,
                  type: contact.type
                }
              }
            ];
          }

          if (!this.internalValue.signee) {
            const permanentRepresentative = this.board.find(member => member.type === '10003');
            if (permanentRepresentative) {
              this.internalValue.signee = permanentRepresentative;
            }
          }
        } catch (err) {
          console.warn(err);
        }
      }
    },
    async forwardSubmit() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$scrollToErrors();
        // ABORT!!
        return false;
      }

      this.$emit('input', {
        row: JSON.parse(JSON.stringify(this.internalValue)),
        verified: JSON.parse(JSON.stringify(this.internalVerified))
      });
      this.$parent.close();
    },
    onCancel() {
      this.$parent.close();
    },
    async onChange(obj) {
      this.internalVerified = JSON.parse(JSON.stringify(obj.verified));
      this.internalValue = this.internalValue = JSON.parse(JSON.stringify(obj.data));
      await this.fetchBoard();
    },
    localizeField(field) {
      const suffix = (field.is_required ? ' *' : '');
      if (!this.locale || this.locale === 'en') {
        const retVal = Object.assign({}, field);
        if (retVal.label) {
          retVal.label += suffix;
        }
        return retVal;
      } else {
        const translations = field.translations || [];
        const translation = translations.find(translation => {
          return translation.lang === this.locale;
        });

        const retVal = Object.assign({}, field, translation);
        if (retVal.label) {
          retVal.label += suffix;
        }
        return retVal;
      }
    },
    async onContact($event) {
      if ($event) {
        delete $event._type;
        this.internalValue.contact = $event;
        await this.fetchBoard();
      } else {
        this.internalValue.contact = $event;
        this.internalValue.signee = undefined;
      }
    },
    async setSignee(id) {
      const item = this.board.find(item => item.id === id);
      this.internalValue.signee = { ...item };
    },
    getRules(field) {
      let rules = field.rules ? field.rules.split('|') : [];

      if (field.type === 'NUMBER') {
        rules.push('number');
      }

      if (field.is_required) {
        rules.push('required');
      }
      if (field.min_value) {
        rules.push('min_value:' + field.min_value);
      }
      if (field.max_value) {
        rules.push('max_value:' + field.max_value);
      }
      if (field.min_date) {
        rules.push('min_date:' + field.min_date);
      }
      if (field.max_date) {
        rules.push('max_date:' + field.max_date);
      }

      return rules.join('|');
    },
    getSigneeName(item) {
      const personName = item.person.name + (item.person.vat_number ? ' ' + formatPersonNumber(item.person.vat_number) : '');

      if (item.enterprise) {
        const enterpriseName = item.enterprise.name + (item.enterprise.vat_number ? ' ' + formatVatNumber(item.enterprise.vat_number, true) : '');
        return enterpriseName + ', ' + this.$t('contact.representedBy') + ' ' + personName;
      }
      return personName;
    }
  }
};
</script>
