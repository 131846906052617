<template>
  <div class="has-text-weight-semibold">
    <span v-if="type === 'CURRENCY'">
      {{ printCurrency(innerValue) }}
    </span>
    <span v-else-if="type === 'DATE'">
      {{ innerValue | formatDate }}
    </span>
    <span v-else-if="type === 'UPLOAD'">
      <a @click="goToS3(innerValue)" v-if="innerValue && innerValue.url">{{ getFileName(innerValue.url) }} {{ innerValue.created_at }}</a>
    </span>
    <span v-else-if="type === 'TEMPLATE_RESULT'">
      <a @click="goToS3(innerValue)" v-if="innerValue && innerValue.url">{{ getFileName(innerValue.url) }} {{ innerValue.created_at }}</a>
    </span>
    <span v-else-if="type === 'UPLOAD_MULTIPLE'">
      <span v-for="(upload, idx) in innerValue" :key="idx">
        <a @click="goToS3(upload.url, upload)">{{ getFileName(upload.url) }}</a> <span>{{ upload.created_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</span
        ><br v-if="idx < innerValue.length - 1" />
      </span>
    </span>
    <span v-else-if="type === 'UPLOAD_SIGNED'">
      <span v-if="innerValue && innerValue.document_to_be_signed">
        Document to sign:
        <a @click="goToS3(innerValue.document_to_be_signed)">{{ getFileName(innerValue.document_to_be_signed) }}</a>
      </span>
      -
      <span v-if="innerValue && innerValue.value">
        Uploaded document:
        <a @click="goToS3(innerValue.value)">{{ getFileName(innerValue.value) }}</a>
      </span>
    </span>
    <span v-else-if="type === 'RADIO'">
      {{ localizeOption(innerValue) }}
    </span>
    <span v-else-if="type === 'CHECKBOX'">
      {{ innerValue ? innerValue.map(item => localizeOption(item)).join(', ') : '' }}
    </span>
    <span v-else-if="type === 'CONSENT'">
      <span v-if="innerValue">{{ innerValue.consented_by }} consented at {{ innerValue.consented_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
      <span v-else>No response received yet</span>
    </span>
    <div v-else-if="type === 'COLLECTION'">
      <DynamicTable
        :initial-value="initialValue"
        :config="config"
        :value="value"
        @update-with-path="$emit('update-with-path', $event)"
        @verify="$emit('verify', $event)"
        :verified="verified"
        :collection="field"
        :horizontal="false"
        :vpath="subVerificationPath"
        :path="subPath" />
    </div>
    <div v-else-if="type === 'CONTACT'">
      <ControlField grouped v-if="innerValue">
        <ControlField :label="innerValue.type + ':'">
          <router-link v-if="user.global_role !== 'BASIC_USER' && innerValue.id" :to="{ name: 'contact', params: { id: innerValue.id }}">
            {{ innerValue.name }}
            <span class="has-text-weight-normal">
              {{ innerValue.type == 'PERSON' ? formatPersonNumber(innerValue.vat_number) : formatVatNumber(innerValue.vat_number, true) }}
            </span>
          </router-link>
          <span v-else>
            {{ innerValue.name }}
            <span class="has-text-weight-normal">
              {{ innerValue.type == 'PERSON' ? formatPersonNumber(innerValue.vat_number) : formatVatNumber(innerValue.vat_number, true) }}
            </span>
          </span>
        </ControlField>
      </ControlField>
    </div>
    <div v-else-if="type === 'GROUP'">
      <div v-if="field._component === 'DynamicGroupPerson'">
        <router-link v-if="user.global_role !== 'BASIC_USER' && innerValue && innerValue.id" :to="{ name: 'contact', params: { id: innerValue.id} }">
          {{ innerValue ? innerValue.name : '' }}
        </router-link>
        <span v-else>
          {{ innerValue ? innerValue.name : '' }}
        </span>
      </div>
      <div v-else-if="field._component === 'DynamicGroupEnterprise'">
        <router-link v-if="user.global_role !== 'BASIC_USER' && innerValue && innerValue.id" :to="{ name: 'contact', params: { id: innerValue.id} }">
          {{ innerValue ? innerValue.name : '' }} {{ innerValue.legalform ? innerValue.legalform.short : '' }} <span class="has-text-weight-normal">{{ innerValue ? formatVatNumber(innerValue.vat_number, true) : '' }}</span>
        </router-link>
        <span v-else-if="innerValue">
          {{ innerValue ? innerValue.name : '' }} {{ innerValue.legalform ? innerValue.legalform.short : '' }} <span class="has-text-weight-normal">{{ innerValue ? formatVatNumber(innerValue.vat_number, true) : '' }}</span>
        </span>
      </div>
      <div v-else-if="field._component === 'DynamicGroupContact'">
        <ControlField grouped v-if="innerValue && innerValue.contact">
          <ControlField :label="innerValue.contact._type + ':'">
            <span v-if="innerValue.contact._type === 'COMPANY'">
              {{ innerValue && innerValue.contact ? innerValue.contact.name : '' }} <span class="has-text-weight-normal">{{ innerValue.contact && innerValue.contact ? formatVatNumber(innerValue.contact.vat_number, true) : '' }}</span>
            </span>
            <span v-if="innerValue._type === 'PERSON'">
              {{ innerValue && innerValue.contact ? innerValue.contact.firstname : '' }} {{ innerValue && innerValue.contact ? innerValue.contact.lastname : '' }}
            </span>
          </ControlField>
        </ControlField>
      </div>
      <DynamicGroup
        v-else
        :initial-value="initialValue"
        :config="config"
        :value="value"
        :verified="verified"
        @update-with-path="$emit('update-with-path', $event)"
        @verify="$emit('verify', $event)"
        :group="field"
        :vpath="subVerificationPath"
        :path="subPath" />
    </div>
    <span v-else-if="type === 'LIST'">
      {{ getLocalizedOption(innerValue).label }}
    </span>
    <span v-else class="has-text-weight-semibold">
      {{ innerValue }}
    </span>
  </div>
</template>

<script>
import Utils from '@/utils';
import { mapGetters, mapState } from 'vuex';
import currencyList from '@/utils/currencies.json';
import UploadService from '@/services/UploadService';
import expressions from 'angular-expressions';
import { formatVatNumber, formatPersonNumber } from '@/utils/formatVatNumber';
import { KboLegalForm } from '@/utils/legallab_contact';
import { CurrencyVal } from '@/utils/CurrencyVal';

export default {
  props: {
    config: {
      type: Object,
      required: true
    },
    initialValue: {
      type: Object,
      default: null
    },
    path: {
      type: String,
      required: true
    },
    vpath: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: null
    },
    value: {
      type: null,
      default: null
    },
    field: {
      type: Object,
      required: true
    },
    verified: {
      type: Object
    }
  },

  data() {
    return {
      CURRENCIES: currencyList,
      innerValue: null
    };
  },

  created() {
    const evaluate = expressions.compile(this.path);
    this.innerValue = evaluate(this.value);
    if (this.innerValue?.legalform) {
      if (!(this.innerValue.legalform instanceof KboLegalForm)) {
        this.innerValue.legalform = new KboLegalForm(this.innerValue.legalform, this.lang);
      }
    }
  },

  watch: {
    value(newVal) {
      const evaluate = expressions.compile(this.path);
      this.innerValue = evaluate(newVal);
      if (this.innerValue?.legalform) {
        if (!(this.innerValue.legalform instanceof KboLegalForm)) {
          this.innerValue.legalform = new KboLegalForm(this.innerValue.legalform, this.lang);
        }
      }
    }
  },

  computed: {
    ...mapState('i18n', ['lang']),
    ...mapGetters({
      user: 'authentication/user'
    }),
    ...mapState('i18n', {
      locale: 'currentLang'
    }),
    subVerificationPath() {
      return this.vpath
    },
    subPath() {
      return this.path
    },
    localisedField() {
      if (!this.lang || this.lang === 'en') {
        return this.field;
      } else {
        const translations = this.field.translations || [];
        const translation = translations.find(translation => {
          return translation.lang === this.lang;
        });

        return translation || { label: this.field.label };
      }
    }
  },

  methods: {
    onInput(value) {
      const newValue = JSON.parse(JSON.stringify(value));
      this.$emit('input', newValue);
    },
    getFileName(value) {
      if (!value) return '';
      return Utils.getFilename(value);
    },
    localizeOption(value) {
      const field = this.field.options.find(item => item.value === value);
      if (!field) return value;

      return field[this.lang] || field.label;
    },
    async goToS3(url, upload) {
      const filename = upload?.legacy_name || null;
      const presigned = await UploadService.getSignedReadUrl(url, filename);
      window.open(presigned.data.url, '_blank');
    },
    getOption(innerValue) {
      const options = this.field.options.find(obj => obj.value == innerValue) || {}
      return options;
    },
    getLocalizedOption(innerValue) {
      const option = this.getOption(innerValue);
      const language = this.config.language || 'en';
      if (language in option) {
        option.label = option[language];
      }
      return option;
    },
    formatVatNumber(value, addZero) {
      return formatVatNumber(value, addZero);
    },
    formatPersonNumber(value) {
      return formatPersonNumber(value);
    },
    toggleVerified() {
      const value = !this.verified[this.vpath] ? { validated_at: new Date(), validated_by: this.user.email } : undefined;
      this.$emit('verify', { vpath: this.vpath, value });
    },
    printCurrency(currency) {
      if (!currency) return '';
      if (!currency.name) return '';
      const val = new CurrencyVal(currency.value, currency.name);
      if (this.lang) {
        val.lang = this.lang;
      }
      return val.toString();
    }
  }
};
</script>
