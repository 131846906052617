<template>
  <div class="field">
    <div :class="{ 'field-body': config.horizontal, 'field-body-table-horizontal': config.horizontal }">
      <component
        v-if="group._component"
        :is="group._component"
        :path="path"
        :value="innerValue"
        :vpath="vpath"
        @verify="$emit('verify', $event)"
        @update-with-path="$emit('update-with-path', $event)"
      />
      <div v-else :class="{ columns: group.columns }" class="field">
        <o-collapse v-if="isCollapsable" v-model="collapsed" animation="slide">
          <template #trigger>
            <div style="position: relative" class="is-flex">
              <div class="chevron-container" style="position: absolute; left: -64px">
                <a href="#" @click.prevent>
                  <span :class="['fas', `fa-chevron-${collapsed ? 'up' : 'down'}`]"/>
                </a>
              </div>
              <DynamicHeader class="is-flex-grow-1" :level="level !== undefined ? level + 1 : level" :title="group.translations[0].label" />

              <span v-if="group.track_progress" class="dynamic-group-progress">
                <progress class="progress is-info group__progress" :value="filledPercent" max="100">{{ filledPercent }}%</progress>
                <span>{{ filledFields }} / {{ filledTotal }}</span>
              </span>
            </div>
            <hr class="mt-0 mb-1"/>
          </template>
          <FormField
            v-for="(field, index) in group.children"
            :key="index"
            :class="{ column: group.columns, 'is-2': field['is-2'], 'is-3': field['is-3'], 'is-4': field['is-4'] }"
            :is-collapsable="isCollapsable"
            :config="internalConfig"
            :disabled="config.disabled || fieldDisabled(field)"
            :field="field"
            :initial-value="initialValue"
            :level="level !== undefined ? level + 1 : level"
            :locale="config.language"
            :path="subPath(field)"
            :value="innerValue"
            :verified="verified"
            :vpath="subVerificationPath(field)"
            @verify="$emit('verify', $event)"
            @update-with-path="$emit('update-with-path', $event)"
          />
        </o-collapse>
        <FormField
          v-for="(field, index) in group.children"
          v-else
          :key="index"
          :class="{ column: group.columns, 'is-2': field['is-2'], 'is-3': field['is-3'], 'is-4': field['is-4'] }"
          :config="internalConfig"
          :disabled="config.disabled || fieldDisabled(field)"
          :field="field"
          :initial-value="initialValue"
          :level="level !== undefined ? level + 1 : level"
          :locale="config.language"
          :path="subPath(field)"
          :value="innerValue"
          :verified="verified"
          :vpath="subVerificationPath(field)"
          @verify="$emit('verify', $event)"
          @update-with-path="$emit('update-with-path', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormField from '@/modules/dynamic-form/components/FormField.vue';
import DynamicHeader from "@/modules/dynamic-form/components/DynamicHeader.vue";
import expressions from 'angular-expressions';
import { debounce } from 'lodash';
import { angularParser } from '@/utils/angularParser';

export default {
  name: 'DynamicGroup',
  components: {
    FormField, DynamicHeader
  },

  props: {
    config: {
      type: Object,
      required: true
    },
    initialValue: {
      type: Object,
      default: null
    },
    path: {
      type: String,
      required: true
    },
    vpath: {
      type: String,
      required: true
    },
    group: {
      type: Object,
      default: () => {
      }
    },
    // must be included in props
    value: {
      type: null,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    verified: {
      type: Object,
      default: () => ({})
    },
    level: {
      type: Number,
      default: undefined
    },
    isCollapsable: {
      type: Boolean,
      default: false,
    }
  },

  data: () => ({
    innerValue: {},
    collapsed: true,
    filledFields: 0,
    filledTotal: 0
  }),

  computed: {
    internalConfig() {
      return Object.assign({}, this.config, {
        allowVerification: !this.group.vpath && this.config.allowVerification,
        alwaysShowLock: this.config.alwaysShowLock || false
      });
    },
    localizedField() {
      const locale = this.config.language;
      if (!locale || locale === 'en') {
        return this.group;
      } else {
        const translations = this.group.translations || [];
        const translation = translations.find(translation => {
          return translation.lang === locale;
        });

        return translation || {label: this.group.label};
      }
    },
    filledPercent() {
      if (this.filledTotal === 0) {
        return 100;
      }
      return Math.floor(100 * this.filledFields / this.filledTotal);
    }
  },

  watch: {
    value: {
      deep: true,
      handler() {
        this.innerValue = JSON.parse(JSON.stringify(this.value));
        this.debounceRecalcProgress(this);
      }
    }
  },

  created() {
    this.debounceRecalcProgress = debounce(async function() {
      const expr = expressions.compile(this.path);
      const value = this.path ? expr(this.innerValue) : this.innerValue;
      [this.filledTotal, this.filledFields] = await this.calcFields(this.group, value, value);
    }, 600);

    if (this.value) {
      this.innerValue = JSON.parse(JSON.stringify(this.value)) || {};
      this.debounceRecalcProgress(this);
    }
  },

  methods: {
    subPath(field) {
      if (this.group.virtual_group) {
        return field.name;
      }
      return this.path + '.' + field.name;
    },
    subVerificationPath(field) {
      if (this.group.virtual_group) {
        return this.vpath;
      }
      return this.vpath + '.' + field.name;
    },
    fieldDisabled(field) {
      if (this.config.disabled) return true;
      return !!this.verified[this.subVerificationPath(field)];
    },
    async calcFields(field, fieldValue, scopeValues) {
      const CALC_FIELDS = ['TEXT', 'NUMBER', 'DATE', 'CURRENCY', 'CHECKBOX', 'RADIO', 'LIST', 'MULTILINE_TEXT', 'CONSENT',
        'UPLOAD', 'UPLOAD_MULTIPLE', 'UPLOAD_SIGNED', 'CONTACT', 'LEGAL_FORM']

      let total = 0;
      let filled = 0;

      if (CALC_FIELDS.indexOf(field.type) > -1) {
        let visible = true;
        if (field.visible_dynamic) {
          const parser = angularParser(field.visible_formula, { lang: this.config.language || 'en' });
          visible = await parser.get(scopeValues);
        }
        if (visible) {
          if (fieldValue) {
            //
            filled++;
          }
          total++;
        }
      } else
      if (['COLLECTION'].indexOf(field.type) > -1 && Array.isArray(field.children)) {
        if (fieldValue) {
          for (const row of fieldValue) {
            for (const child of field.children) {
              const cellValue = row[child.name];
              const res = await this.calcFields(child, cellValue, Object.assign({}, fieldValue, row[child.name]));
              total += res[0];
              filled += res[1];
            }
          }
        }
      } else
      if (['GROUP'].indexOf(field.type) > -1 && Array.isArray(field.children)) {
        for (const child of field.children) {
          const childValue = fieldValue ? fieldValue[child.name] : undefined;
          const res = await this.calcFields(child, childValue, Object.assign({}, fieldValue, fieldValue ? fieldValue[child.name] : undefined));
          total += res[0];
          filled += res[1];
        }
      }

      return [total, filled];
    }
  }
};
</script>
