<template>
  <div class="field">
    <div class="columns m-0" v-if="innerValue.id">
      <router-link :to="{name: 'contact', params: { id: innerValue.id } } ">{{ innerValue.name }} {{ innerValue.vat_number }}</router-link>
      <button class="button is-small is-align-self-flex-end" @click="unlink">Unlink</button>
    </div>
    <div class="columns m-0" v-else>
      <ControlField label="Type">
        <TableFilterSelect :value="innerValue.type" @input="onInput('type', $event)" :items="clientTypeItems" allow-empty="Select type" />
      </ControlField>

      <ValidatedInput v-if="innerValue.type === 'COMPANY'" :value="innerValue.name" @input="onInput('name', $event)" rules="" label="Name" :horizontal="false" />
      <ValidatedInput v-if="innerValue.type === 'COMPANY'" mask="vat_number" :value="innerValue.vat_number" @input="onInput('vat_number', $event)" rules="" label="Number" :horizontal="false" />
      <button v-if="innerValue.type === 'COMPANY' && innerValue.vat_number" class="button is-small is-align-self-flex-end" @click="link">Link</button>

      <ValidatedInput v-if="innerValue.type === 'PERSON'" :value="innerValue.firstname" @input="onInput('firstname', $event)" rules="" label="Firstname" :horizontal="false" />
      <ValidatedInput v-if="innerValue.type === 'PERSON'" :value="innerValue.lastname" @input="onInput('lastname', $event)" rules="" label="Lastname" :horizontal="false" />
      <button v-if="innerValue.type === 'PERSON' && innerValue.firstname && innerValue.lastname" class="button is-small is-align-self-flex-end" @click="link">Link</button>
    </div>
  </div>
</template>
<script>
import enums from '@/utils/enums';
import ValidatedInput from '@/components/form/ValidatedInput.vue';
import expressions from 'angular-expressions';
import ContactService from '@/services/ContactService';
import ContactLinkDialog from './ContactLinkDialog.vue';
import TableFilterSelect from '@/components/form/TableFilterSelect.vue';

export default {
  components: { ValidatedInput, TableFilterSelect },
  props: {
    path: {
      type: String
    },
    vpath: {
      type: String
    },
    value: {
      type: Object,
      default: () => ({
        type: ''
      })
    }
  },
  data: () => ({
    innerValue: ({
      type: ''
    })
  }),
  computed: {
    clientTypeItems() {
      const retVal = [];
      for (let type in enums.ContactType) {
        retVal.push({
          field: type,
          label: enums.ContactType[type]
        });
      }

      return retVal.sort((a, b) => a.label.localeCompare(b.label));
    }
  },

  watch: {
    value: {
      deep: true,
      handler() {
        const evaluate = expressions.compile(this.path);
        this.innerValue = evaluate(this.value) || { type: ''};
      }
    }
  },

  created() {
    if (this.value) {
      const evaluate = expressions.compile(this.path);
      this.innerValue = evaluate(this.value) || { type: '' };
    }
  },

  methods: {
    onInput(name, value) {
      this.innerValue[name] = value;
      this.$emit('update-with-path', {
        path: this.path,
        value: this.innerValue
      });
    },
    unlink() {
      this.onInput('id', undefined);
    },
    async link(item) {
      if (this.innerValue.type === 'PERSON') {
        delete item.vat_number;
      }
      const response = await ContactService.link(Object.assign({}, this.innerValue, item));
      if (this.innerValue.type === 'PERSON') {
        if (response.data?.name) {
          this.onInput('name', response.data?.name);
        }
        if (response.data?.vat_number) {
          this.onInput('vat_number', response.data?.vat_number);
        }

        if (response.data?.id) {
          this.onInput('id', response.data?.id);
          return;
        }

        if (response.data?.existing) {
          this.$buefy.modal.open({
            parent: this,
            component: ContactLinkDialog,
            hasModalCard: true,
            props: {
              existing: response.data?.existing
            },
            events: {
              create: async () => {
                await this.link(Object.assign({}, this.innerValue, { force: true }));
              },
              link: async (row) => {
                await this.link(row);
              }
            }
          });
        }
      }
      if (this.innerValue.type === 'COMPANY') {
        if (response.data?.name) {
          this.onInput('name', response.data?.name);
        }
        if (response.data?.vat_number) {
          this.onInput('vat_number', response.data?.vat_number);
        }

        if (response.data?.id) {
          this.onInput('id', response.data?.id);
          return;
        }

        if (response.data?.existing) {
          this.$buefy.modal.open({
            parent: this,
            component: ContactLinkDialog,
            hasModalCard: true,
            props: {
              existing: response.data?.existing
            },
            events: {
              create: async () => {
                await this.link(Object.assign({}, this.innerValue));
              },
              link: async (row) => {
                await this.link(row);
              }
            }
          });
        }
      }
    }
  }

};
</script>
