<template>
  <ControlField label="Company">
    <div class="columns m-0" v-if="innerValue.id">
      <router-link :to="{name: 'contact', params: { id: innerValue.id } } ">{{ innerValue.name }} {{ innerValue.vat_number }}</router-link>
      <button class="button is-small is-align-self-flex-end" @click="unlink">Unlink</button>
    </div>
    <div class="columns m-0" v-else>
      <ValidatedInput
        :value="innerValue.name"
        rules=""
        label="Name"
        :horizontal="false"
        @input="onInput('name', $event)"
      />
      <ValidatedInput
        mask="vat_number"
        :value="innerValue.vat_number"
        rules=""
        label="Number"
        :horizontal="false"
        @input="onInput('vat_number', $event)"
      />
      <button v-if="innerValue.vat_number" class="button is-small is-align-self-flex-end" @click="link">Link</button>
    </div>
  </ControlField>
</template>
<script>
import ValidatedInput from '@/components/form/ValidatedInput.vue';
import expressions from 'angular-expressions';
import ContactService from '@/services/ContactService';
import ContactLinkDialog from './ContactLinkDialog.vue';
export default {
  components: { ValidatedInput },
  props: {
    vpath: {
      type: String
    },
    path: {
      type: String
    },
    value: {
      type: Object
    }
  },
  data: () => ({
    innerValue: ({})
  }),
  watch: {
    value: {
      deep: true,
      handler() {
        const evaluate = expressions.compile(this.path);
        this.innerValue = evaluate(this.value) || {};
      }
    }
  },

  created() {
    if (this.value) {
      const evaluate = expressions.compile(this.path);
      this.innerValue = evaluate(this.value) || {};
    }
  },

  methods: {
    onInput(name, value) {
      this.innerValue[name] = value;
      this.$emit('update-with-path', {
        path: this.path,
        value: this.innerValue
      });
    },
    unlink() {
      this.onInput('id', undefined);
    },
    async link(item) {
      const response = await ContactService.link(Object.assign({}, this.innerValue, { type: 'COMPANY' }, item));

      if (response.data?.name) {
        this.onInput('name', response.data?.name);
      }
      if (response.data?.vat_number) {
        this.onInput('vat_number', response.data?.vat_number);
      }

      if (response.data?.id) {
        this.onInput('id', response.data?.id);
        return;
      }

      if (response.data?.existing) {
        this.$buefy.modal.open({
          parent: this,
          component: ContactLinkDialog,
          hasModalCard: true,
          props: {
            existing: response.data?.existing
          },
          events: {
            create: async () => {
              await this.link(Object.assign({}, this.innerValue, { type: 'COMPANY', force: true }));
            },
            link: async (row) => {
              await this.link(row);
            }
          }
        });
      }
    }
  }

};
</script>
