<template>
  <div class="modal-card" style="width: 600px">
    <header class="modal-card-head">
      <p v-if="!editMode" class="modal-card-title">{{ $t('common.addRow') }}</p>
      <p v-if="editMode" class="modal-card-title">{{ $t('common.editRow') }}</p>
    </header>
    <section class="modal-card-body">
      <ValidationObserver ref="observer" v-slot="{ invalid }" @submit.prevent="() => {}">
        <ValidationProvider v-slot="{ errors, valid }" rules="required" name="Type" vid="type" slim>
          <ControlField :label="$t('common.type')" :type="{ 'is-danger': errors[0] }" :message="errors">
            <b-select :value="type" name="type" :placeholder="$t('common.type')" @input="typeChanged">
              <option v-for="(value, name) in ContactType" :key="name" :value="name">{{ $t(value) }}</option>
            </b-select>
          </ControlField>
        </ValidationProvider>

        <div v-if="type === 'PERSON'">
          <DynamicContact
            v-model="internalValue.person"
            :config="config"
            :horizontal="config.horizontal"
            :label="personField.label"
            :description="personField.description"
            :field="{ contact_type: 'PERSON' }"
          />

          <ValidatedSelect
            v-model="internalValue.type"
            :allow-verification="config.allowVerification"
            :horizontal="config.horizontal"
            :options="typeField.options"
            :reduce="option => option['value']"
            :option-label="locale"
            fallback-option-label="label"
            :rules="getRules(typeField)"
            :label="typeField.label"
            :placeholder="typeField.label"
            :get-option-label="option => (option[locale] ? option[locale] : option.label)"
            :vid="typeField.name"
            :message="typeField.description"
          />

          <ValidatedDate
            v-model="internalValue.period.begin"
            :horizontal="config.horizontal"
            :options="beginField.options"
            :reduce="option => option[locale] || option['label']"
            :option-label="locale"
            fallback-option-label="label"
            :rules="getRules(beginField)"
            :label="periodField.label"
            :placeholder="periodField.label"
            :get-option-label="option => (option[locale] ? option[locale] : option.label)"
            :vid="beginField.name"
            :message="periodField.description"
          />
        </div>
        <div v-if="type === 'COMPANY'">
          <DynamicContact
            v-model="internalValue.enterprise"
            :config="config"
            :horizontal="config.horizontal"
            :label="companyField.label"
            :description="companyField.description"
            :field="{ contact_type: 'COMPANY' }"
          />

          <ValidatedSelect
            v-model="internalValue.type"
            :allow-verification="config.allowVerification"
            :horizontal="config.horizontal"
            :options="typeField.options"
            :reduce="option => option['value']"
            :option-label="locale"
            fallback-option-label="label"
            :rules="getRules(typeField)"
            :label="typeField.label"
            :placeholder="typeField.label"
            :get-option-label="option => (option[locale] ? option[locale] : option.label)"
            :vid="typeField.name"
            :message="typeField.description"
          />

          <ValidatedDate
            v-model="internalValue.period.begin"
            :horizontal="config.horizontal"
            :options="beginField.options"
            :reduce="option => option[locale] || option['label']"
            :option-label="locale"
            fallback-option-label="label"
            :rules="getRules(beginField)"
            :label="periodField.label"
            :placeholder="periodField.label"
            :get-option-label="option => (option[locale] ? option[locale] : option.label)"
            :vid="beginField.name"
            :message="periodField.description"
          />

          <DynamicContact
            v-if="hasRepresentative"
            v-model="internalValue.person"
            :config="config"
            :horizontal="config.horizontal"
            :label="$t('contact.representedBy')"
            :description="personField.description"
            :field="{ contact_type: 'PERSON' }"
          />
        </div>

        <slot name="buttons">
          <div style="margin-top:1em; text-align:right;">
            <div class="buttons is-pulled-right">
              <button type="button" class="button is-outlined is-primary" @click="onCancel">{{ $t('form.cancel') }}</button>
              <button type="button" class="button is-primary" @click="forwardSubmit" v-if="!config.disabled && !config.readonly">{{ $t('form.save') }}</button>
            </div>
          </div>
        </slot>
      </ValidationObserver>
    </section>
  </div>
</template>

<script>
import ValidatedSelect from '@/components/form/ValidatedSelect.vue';
import DynamicContact from '@/modules/dynamic-form/components/DynamicContact.vue';
import ValidatedDate from '@/components/form/ValidatedDate.vue';
import Enums from '@/utils/enums';

export default {
  name: 'BoardRowDialog',
  components: {
    ValidatedSelect, DynamicContact, ValidatedDate
  },
  props: {
    vpath: {
      type: String,
      default: ''
    },
    config: {
      type: Object,
      required: true
    },
    editMode: {
      type: Boolean,
      default: false
    },
    fields: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: null
    },
    verified: {
      type: Object,
      default: null
    },
    onSubmit: {
      type: Function,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      type: '',
      ContactType: Enums.ContactType,
      internalVerified: {},
      internalValue: {
        period: {}
      }
    }
  },

  computed: {
    locale() {
      return this.config.language;
    },
    personField() {
      const field = this.fields.find(f => f.name === 'person');
      return this.localizeField(field);
    },
    companyField() {
      const field = this.fields.find(f => f.name === 'enterprise');
      return this.localizeField(field);
    },
    typeField() {
      const field = this.fields.find(f => f.name === 'type');
      return this.localizeField(field);
    },
    periodField() {
      const field = this.fields.find(f => f.name === 'period');
      return this.localizeField(field);
    },
    beginField() {
      const field = this.fields.find(f => f.name === 'period').children.find(f => f.name === 'begin');
      return this.localizeField(field);
    },
    hasRepresentative() {
      if (this.internalValue.person) {
        return true;
      }
      if (['10002', '10007', '10006'].indexOf(this.internalValue.type) > -1) {
        return true;
      }
      return false;
    }
  },

  created() {
    if (this.value) {
      this.internalValue = Object.assign({ period: {} }, this.value);
      if (this.internalValue.enterprise?.name || this.internalValue.enterprise?.vat_number) {
        this.type = 'COMPANY';
      } else
      if (this.internalValue.person?.firstname || this.internalValue.person?.lastname) {
        this.type = 'PERSON';
      }
    }
    if (this.verified) {
      this.internalVerified = this.verified;
    }
  },

  methods: {
    async forwardSubmit() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$scrollToErrors();
        // ABORT!!
        return false;
      }

      this.$emit('input', {
        row: JSON.parse(JSON.stringify(this.internalValue)),
        verified: JSON.parse(JSON.stringify(this.internalVerified))
      });
      this.$parent.close();
    },
    onCancel() {
      this.$parent.close();
    },
    onChange(obj) {
      this.internalVerified = JSON.parse(JSON.stringify(obj.verified));
      this.internalValue = this.internalValue = Object.assign({ period: {} }, JSON.parse(JSON.stringify(obj.data)));
    },
    localizeField(field) {
      const suffix = (field.is_required ? ' *' : '');
      if (!this.locale || this.locale === 'en') {
        const retVal = Object.assign({}, field);
        if (retVal.label) {
          retVal.label += suffix;
        }
        return retVal;
      } else {
        const translations = field.translations || [];
        const translation = translations.find(translation => {
          return translation.lang === this.locale;
        });

        const retVal = Object.assign({}, field, translation);
        if (retVal.label) {
          retVal.label += suffix;
        }
        return retVal;
      }
    },
    getRules(field) {
      let rules = field.rules ? field.rules.split('|') : [];

      if (field.type === 'NUMBER') {
        rules.push('number');
      }

      if (field.is_required) {
        rules.push('required');
      }
      if (field.min_value) {
        rules.push('min_value:' + field.min_value);
      }
      if (field.max_value) {
        rules.push('max_value:' + field.max_value);
      }
      if (field.min_date) {
        rules.push('min_date:' + field.min_date);
      }
      if (field.max_date) {
        rules.push('max_date:' + field.max_date);
      }

      return rules.join('|');
    },
    typeChanged(newType) {
      if (newType === 'PERSON') {
        delete this.internalValue.enterprise;
        delete this.internalValue.person;
      } else {
        delete this.internalValue.enterprise;
        delete this.internalValue.person;
      }

      delete this.internalValue.type;
      this.internalValue.period = {};

      this.type = newType;
    }
  }
};
</script>
