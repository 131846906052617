<template>
  <div class="modal-card" style="width: 800px">
    <header class="modal-card-head">
      <p v-if="!editMode" class="modal-card-title">{{ $t('common.addRow') }}</p>
      <p v-if="editMode" class="modal-card-title">{{ $t('common.editRow') }}</p>
    </header>
    <section class="modal-card-body">
      <DynamicForm
        class="dynamic-form-left-margin"
        :config="config"
        :disabled="disabled"
        :fields="fields"
        :value="internalValue"
        :upload-sub-dir="uploadSubDir"
        :verified="internalVerified"
        @update="onChange"
        @submit="forwardSubmit"
        @cancel="onCancel"
      />
    </section>
  </div>
</template>

<script>
import DynamicForm from './DynamicForm.vue';
export default {
  name: 'DynamicTableRowDialog',
  components: {
    DynamicForm
  },
  props: {
    vpath: {
      type: String,
      default: ''
    },
    config: {
      type: Object,
      required: true
    },
    editMode: {
      type: Boolean,
      default: false
    },
    fields: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: null
    },
    verified: {
      type: Object,
      default: null
    },
    uploadSubDir: {
      type: String,
      default: null
    },
    onSubmit: {
      type: Function,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      internalVerified: {},
      internalValue: {}
    }
  },

  created() {
    if (this.value) {
      this.internalValue = this.value;
    }
    if (this.verified) {
      this.internalVerified = this.verified;
    }
  },

  methods: {
    forwardSubmit() {
      this.$emit('input', {
        row: JSON.parse(JSON.stringify(this.internalValue)),
        verified: JSON.parse(JSON.stringify(this.internalVerified))
      });
      this.$parent.close();
    },
    onCancel() {
      this.$parent.close();
    },
    onChange(obj) {
      this.internalVerified = JSON.parse(JSON.stringify(obj.verified));
      this.internalValue = JSON.parse(JSON.stringify(obj.data));
    }
  }
};
</script>
