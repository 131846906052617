<template>
  <div>
    {{ getSigneeName(innerValue) }}
  </div>
</template>
<script>
import expressions from "angular-expressions";
import {formatPersonNumber, formatVatNumber} from "@/utils/formatVatNumber";

export default {
  props: {
    path: {
      type: String,
      required: true
    },
    value: {
      type: null,
      default: null
    },
  },
  data() {
    return {
      innerValue: null
    }
  },
  created() {
    const evaluate = expressions.compile(this.path);
    this.innerValue = evaluate(this.value);
  },
  watch: {
    value() {
      const evaluate = expressions.compile(this.path);
      this.innerValue = evaluate(this.value);
    }
  },
  methods: {
    getSigneeName(item) {
      if (!item) {
        return '';
      }
      const personName = item.person.name + (item.person.vat_number ? ' ' + formatPersonNumber(item.person.vat_number) : '');

      if (item.enterprise) {
        const enterpriseName = item.enterprise.name + (item.enterprise.vat_number ? ' ' + formatVatNumber(item.enterprise.vat_number, true) : '');
        return enterpriseName + ', ' + this.$t('contact.representedBy') + ' ' + personName;
      }
      return personName;
    }
  }
};
</script>
