<template>
  <o-tooltip
    ref="tooltip"
    position="bottom"
    trigger-classes="display-inline"
    root-classes="display-inline"
    :append-to-body="true"
    :label="error"
  >
    <span class="has-background-danger" v-html="html"></span>
  </o-tooltip>
</template>
<script>
export default {
  computed: {
    html() {
      return this.$root._props['html'];
    },
    error() {
      return this.$root._props['error'];
    }
  },
  mounted() {
    const origUpdateAppendToBody = this.$refs.tooltip.updateAppendToBody;
    this.$refs.tooltip.updateAppendToBody = function () {
      Function.apply(this, origUpdateAppendToBody);
        this.$data.bodyEl.style.zIndex = this.isActive || this.always ? '1005' : '-1'
        this.$data.bodyEl.style.pointerEvents = 'none';
    };

    const interval = setInterval(() => {
      if (!document.body.contains(this.$el)) { // Hack because disconnectedCallback seems not to work
        clearInterval(interval);
        this.$root.$destroy();
        return;
      }
      this.updatePosition();
    }, 200);
  },
  methods: {
    updatePosition() {
      if (!this.$refs.tooltip) {
        return;
      }
      const trigger = this.$refs.tooltip.$refs.trigger;
      const rect = trigger.getBoundingClientRect();
      const top = rect.top + window.scrollY;
      const left = rect.left + window.scrollX;
      const wrapper = this.$refs.tooltip.$data.bodyEl;
      wrapper.style.position = 'absolute';
      wrapper.style.top = `${top}px`;
      wrapper.style.left = `${left}px`;
    }
  }
}
</script>
