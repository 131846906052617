<template>
  <div class="dynamic-contact">
    <div v-if="internalValue && internalValue.id" class="m-0">
      <h2 class="party-name mb-0" v-if="internalValue.name">
        <router-link :to="{ name: 'contact', params: { id: internalValue.id } }">{{ internalValue.name }}</router-link>
        <a href="#" @click.prevent="onUpdateSelectedContacts(null)" class="is-pulled-right mr-2" v-if="!config.readonly && !verified">
          <span class="fas fa-window-close" size="is-small" :disabled="verified"/>
        </a>
      </h2>
      <div v-if="internalValue.type === 'COMPANY'" class="vat-number mb-1">{{ formatVatNumber(internalValue.vat_number, true) }}</div>
      <div v-if="internalValue.type === 'PERSON'" class="vat-number mb-1">{{ formatPersonNumber(internalValue.vat_number, true) }}</div>
      <div class="address">
        <div>{{ internalValue.address }} {{ internalValue.address_second }}</div>
        <div>{{ internalValue.postal_code }} {{ internalValue.city }}</div>
        <div>{{ internalValue.country }}</div>
      </div>

      <div v-if="config.allowAlterShareholders">
        <div v-if="internalValue.type === 'COMPANY'">
          <o-collapse :open="shareholdersOpened" @update:open="toggleShareHolders" :class="{ 'mb-5': !shareholdersOpened }">
            <template #trigger="props">
              <h3 class="is-clickable">
                {{ !Array.isArray(value.shareholders) ? $t('contact.alterShareholders') : $t('contact.shareholders') }}
                <a><o-icon :icon="props.open ? 'caret-up' : 'caret-down'"></o-icon></a>
              </h3>
            </template>
            <div v-if="Array.isArray(value.shareholders)">
              <button @click="resetShareHolders" class="button is-primary is-small" type="button">{{ $t('contact.resetShareholders') }}</button>
              <CompanyForm
                :include="['shareholders']"
                :value="value"
                :config="{ allowMainTableVerification: false, readonly: config.readonly, disabled: config.readonly || verified, hideHeaders: true }"
                @update="onShareholderChange"
              />
            </div>
          </o-collapse>
        </div>
      </div>

    </div>
    <ContactSearch :value="searchValue" :tenant_id="tenant_id" :verified="verified" v-else @update:selectedItems="onUpdateSelectedContacts" :type="field.contact_type" />
  </div>
</template>
<script>
import ContactService from '@/services/ContactService';
import { formatPersonNumber, formatVatNumber } from '@/utils/formatVatNumber';
import CompanyForm from "../../contact/components/CompanyForm.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

const AlterShareHolderMixin = {
  components: {
    CompanyForm
  },
  computed: {
    shareholdersOpened() {
      if (!Array.isArray(this.internalValue.data?.shareholders)) {
        return false;
      }

      return this.internalValue.shareholders_open || false;
    }
  },
  methods: {
    toggleShareHolders(opened) {
      if ('undefined' === typeof this.internalValue.data?.shareholders) {
        this.alterShareHolders();
        return;
      }

      const shareholders_open = opened;
      const shareholders = this.internalValue.data?.shareholders || undefined;

      this.$emit('input', {
        _type: 'Contact',
        id: this.internalValue?.id,
        type: this.internalValue?.type,
        name: this.internalValue?.name || (this.internalValue?.firstname + ' ' + this.internalValue?.lastname),
        firstname: this.internalValue?.firstname,
        lastname: this.internalValue?.lastname,
        vat_number: this.internalValue?.vat_number,
        shareholders,
        shareholders_open
      });
    },
    alterShareHolders() {
      this.$emit('input', {
        _type: 'Contact',
        id: this.internalValue?.id,
        type: this.internalValue?.type,
        name: this.internalValue?.name || (this.internalValue?.firstname + ' ' + this.internalValue?.lastname),
        firstname: this.internalValue?.firstname,
        lastname: this.internalValue?.lastname,
        vat_number: this.internalValue?.vat_number,
        shareholders: this.internalValue.data?.shareholders || [],
        shareholders_open: true
      });
    },
    resetShareHolders() {
      this.$buefy.modal.open({
        parent: this,
        component: ConfirmDialog,
        hasModalCard: true,
        props: {
          title: this.$t('contact.confirmResetShareHolders'),
          message: this.$t('contact.confirmResetShareHoldersDesc'),
          onOk: async () => {
            this.$emit('input', {
              _type: 'Contact',
              id: this.internalValue?.id,
              type: this.internalValue?.type,
              name: this.internalValue?.name || (this.internalValue?.firstname + ' ' + this.internalValue?.lastname),
              firstname: this.internalValue?.firstname,
              lastname: this.internalValue?.lastname,
              vat_number: this.internalValue?.vat_number,
              shareholders: undefined,
              shareholders_open: undefined
            });
          }
        }
      });
    },
    onShareholderChange(value) {
      if (!this.config.allowAlterShareholders) {
        return;
      }

      let shareholders = value.data?.shareholders || undefined;
      this.$emit('input', {
        _type: 'Contact',
        id: this.internalValue?.id,
        type: this.internalValue?.type,
        name: this.internalValue?.name || (this.internalValue?.firstname + ' ' + this.internalValue?.lastname),
        firstname: this.internalValue?.firstname,
        lastname: this.internalValue?.lastname,
        vat_number: this.internalValue?.vat_number,
        shareholders,
        shareholders_open: this.internalValue.shareholders_open
      });
    }
  }
};

export default {
  name: 'DynamicContact',
  mixins: [ AlterShareHolderMixin ],
  props: {
    vid: {
      type: String,
      default: ''
    },
    config: {
      type: Object
    },
    field: {
      type: Object
    },
    value: {
      type: Object,
      default: null
    },
    verified: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      internalValue: null
    }
  },
  computed: {
    tenant_id() {
      return this.config.tenant_id;
    },
    searchValue() {
      return {
        vat_number: this.internalValue?.vat_number,
        name: this.internalValue?.name,
        type: this.internalValue?.type,
      };
    }
  },
  async created() {
    if (this.value?.id) {
      try {
        const response = await ContactService.getOne(this.value.id);
        this.internalValue = response?.data || null;
      } catch (err) {
        this.internalValue = {
          vat_number: this.value?.vat_number,
          name: this.value?.name,
          firstname: this.value?.firstname,
          lastname: this.value?.lastname,
        }
      }
    } else {
      this.internalValue = {
        vat_number: this.value?.vat_number,
        name: this.value?.name,
        firstname: this.value?.firstname,
        lastname: this.value?.lastname,
      }
    }
    this.internalValue.shareholders_open = this.value.shareholders_open;
    await this.validate();
  },
  watch: {
    async value(newVal) {
      if (newVal?.id) {
        try {
          const response = await ContactService.getOne(newVal.id);
          this.internalValue = response?.data || null;
        } catch (err) {
          this.internalValue = {
            vat_number: newVal?.vat_number,
            name: newVal?.name,
            firstname: newVal?.firstname,
            lastname: newVal?.lastname,
          }
        }
      } else {
        this.internalValue = null;
      }
      this.internalValue.shareholders_open = this.value.shareholders_open;
      await this.validate();
    }
  },
  methods: {
    onSubmit() {
    },
    async onUpdateSelectedContacts(selectedItems) {
      if (selectedItems && selectedItems.length > 0) {
        this.internalValue = selectedItems[0];

        this.$emit('input', {
          _type: 'Contact',
          id: this.internalValue?.id,
          type: this.internalValue?.type,
          name: this.internalValue?.name || (this.internalValue?.firstname + ' ' + this.internalValue?.lastname),
          firstname: this.internalValue?.firstname,
          lastname: this.internalValue?.lastname,
          vat_number: this.internalValue?.vat_number
        });
      } else {
        this.$emit('input', null);
      }
      await this.validate();
    },
    formatVatNumber(value, addZero) {
      return formatVatNumber(value, addZero);
    },
    formatPersonNumber(value) {
      return formatPersonNumber(value);
    },
    async validate() {
      if (!this.$refs.provider) {
        return ;
      }
    }
  }
}
</script>
