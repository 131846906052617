import Vue from 'vue';
import sketenv from  '@/utils/environment-config';
import i18n from '@/i18n/i18n';
import './vee-validate';
import App from './App.vue';
import Buefy from 'buefy';
// @ts-ignore
import Oruga from '@oruga-ui/oruga';
import { bulmaConfig } from '@oruga-ui/theme-bulma';
import router from '@/router/index';
import store from './store/store';
import filters from '@/common/filters';
import ResponsiveAutocomplete from '@/components/ResponsiveAutocomplete';
import InlineIcon from '@/components/InlineIcon.vue';
import * as Sentry from "@sentry/vue";
import { Integrations } from '@sentry/tracing';
import dayjs from 'dayjs';

import VueSvgInlinePlugin from 'vue-svg-inline-plugin';
import DynamicTableRowDialog from '@/modules/dynamic-form/components/DynamicTableRowDialog.vue';
import BoardRowDialog from '@/modules/dynamic-form/components/BoardRowDialog.vue';
import BoardAddRowDialog from '@/modules/dynamic-form/components/BoardAddRowDialog.vue';
import ShareholderRowDialog from '@/modules/dynamic-form/components/ShareholderRowDialog.vue';
import {installErrorSpan} from "@/components/error-span/install";

if (import.meta.env.VITE_SENTRY_DSN) {
  const ENVIRONMENT_NAME = String(sketenv.VITE_ENVIRONMENT_NAME ? sketenv.VITE_ENVIRONMENT_NAME : 'local');
  Sentry.init({
    dsn: sketenv.VITE_SENTRY_DSN,
    release: sketenv.GIT_SHA,
    Vue,
    integrations: [new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "app.thelegallab.be", "dev-app.thelegallab.be", /^\//],
    })],
    tracingOptions: {
      trackComponents: true
    },
    tracesSampleRate: 1.0,
    environment: ENVIRONMENT_NAME
  });

  Sentry.setContext('build', {
    GIT_SHA: sketenv.GIT_SHA,
    BUILD_TIME: sketenv.BUILD_TIME,
    VERSION: sketenv.VERSION
  });
}

Date.prototype.toJSON = function() {
  return dayjs(this).format();
};

window.addEventListener('unhandledrejection', async (event) => {
  const err = event.reason;
  if (err.isHttpError) {
    switch (err.status) {
      case 400:
        Sentry.captureException(err);
        await alertCenter.error(err.message);
        return;
      case 401:
        await alertCenter.error('Unauthorized Request. The page did not load correctly.');
        return;
      case 404:
        await router.push('/error');
        return;
    }
  }
  // eslint-disable-next-line no-console
  console.error('unhandledrejection', event);
  Sentry.captureException(err);
});

Vue.config.errorHandler = async (err) => {
  if (err['isHttpError']) {
    const errHttp = <HttpError>err;
    switch (errHttp.status) {
      case 400:
        Sentry.captureException(errHttp);
        await alertCenter.error(errHttp.message);
        return;
      case 401:
        await alertCenter.error('Unauthorized Request. The page did not load correctly.');
        return;
      case 404:
        await router.push('/error');
        return;
    }
  }
  Sentry.captureException(err);
};

Vue.config.productionTip = false;

Vue.component('InlineIcon', InlineIcon);

Vue.use(Oruga, { ...bulmaConfig, iconPack: 'fas' });

Vue.use(Buefy, {
  defaultIconPack: 'fas',
  defaultIconComponent: 'InlineIcon'
});

// register global utility filters.
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

Vue.component('RAutocomplete', ResponsiveAutocomplete);
Vue.component('DynamicTableRowDialog', DynamicTableRowDialog);
Vue.component('BoardRowDialog', BoardRowDialog);
Vue.component('BoardAddRowDialog', BoardAddRowDialog);
Vue.component('ShareholderRowDialog', ShareholderRowDialog);

import ControlField from './components/form/ControlField.vue';
Vue.component('ControlField', ControlField);
import DynamicTable from './modules/dynamic-form/components/DynamicTable.vue';
Vue.component('DynamicTable', DynamicTable);
import DynamicGroup from './modules/dynamic-form/components/DynamicGroup.vue';
Vue.component('DynamicGroup', DynamicGroup);
import DynamicArray from './modules/dynamic-form/components/DynamicArray.vue';
Vue.component('DynamicArray', DynamicArray);
import DynamicContact from './modules/dynamic-form/components/DynamicContact.vue';
Vue.component('DynamicContact', DynamicContact);
import ContactSearch from '@/modules/contact/components/ContactSearch.vue';
Vue.component('ContactSearch', ContactSearch);
import DynamicGroupPerson from '@/modules/dynamic-form/components/DynamicGroupPerson.vue';
Vue.component('DynamicGroupPerson', DynamicGroupPerson);
import DynamicGroupEnterprise from '@/modules/dynamic-form/components/DynamicGroupEnterprise.vue';
Vue.component('DynamicGroupEnterprise', DynamicGroupEnterprise);
import DynamicGroupContact from '@/modules/dynamic-form/components/DynamicGroupContact.vue';
import enums from '@/utils/enums';
import { alertCenter, AlertMixin } from '@/alert';
import { HttpError } from '@/utils/http';
Vue.component('DynamicGroupContact', DynamicGroupContact);
import SigneeCell from '@/modules/dynamic-form/components/SigneeCell.vue';
Vue.component('SigneeCell', SigneeCell);

Vue.use(VueSvgInlinePlugin);
VueSvgInlinePlugin.install(Vue, {
  attributes: {
    data: ['src'],
    remove: ['alt']
  }
});

Vue.mixin({
  methods: {
    hasRole(roles) {
      if (!Array.isArray(roles)) roles = [roles];
      if (roles.indexOf(store.getters['authentication/global_role']) > -1) return true;
      if (roles.indexOf('ROOT') > -1 && store.getters['authentication/global_role'] === 'ADMIN') {
        return (store.state.authentication.user.tenant_id === enums.ROOT_UID);
      }
      return false;
    }
  }
});

installErrorSpan(Vue);

Vue.filter('excerpt', function(html, length) {
  if (!length) length = 200;
  const d = document.createElement('div');
  d.innerHTML = html;
  const rawText = d.textContent;
  if (rawText.length <= length) return rawText;
  const text = rawText.substr(0, length);
  return text ? text + '...' : '';
});

Vue.mixin(AlertMixin);

store.dispatch('i18n/initLang');
store.dispatch('theme/initTheme');
store.dispatch('notification/refreshNotification');
store.dispatch('init');

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
