/* eslint-disable no-console */
import { API_LEVEL } from '@/utils/enums';

function getEnvironmentConfig() {
  const sketenv = {
    GIT_SHA: String(import.meta.env.VITE_GIT_SHA || import.meta.env.GIT_SHA),
    BUILD_TIME: import.meta.env.VITE_BUILD_TIME || import.meta.env.BUILD_TIME,
    VITE_SENTRY_DSN: String(import.meta.env.VITE_SENTRY_DSN),
    VERSION: import.meta.env.VITE_VERSION || import.meta.env.VERSION,
    VITE_ENVIRONMENT_NAME: import.meta.env.VITE_ENVIRONMENT_NAME,
    API_BASE_URL: import.meta.env.VITE_API_BASE_URL,
    API_LEVEL: API_LEVEL
  };

  console.info('Using configurable environment', sketenv);
  window['sketenv'] = sketenv;

  return sketenv;
}

export default getEnvironmentConfig();
