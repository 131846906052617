import ErrorSpan from './ErrorSpan.vue';

export function installErrorSpan(Vue) {
    class ErrorSpanElement extends HTMLElement {
        private instance: any;
        constructor() {
            super();
            this.instance = new Vue({
                props: ['html', 'error'],
                propsData: {
                    html: this.innerHTML,
                    error: this.getAttribute('title')
                },
                render: h => h(ErrorSpan),
            })
            this.instance.$mount(this);
        }

        disconnectedCallback() {
            console.log('disconnectedCallback');
            this.instance.$destroy();
        }
    }
    window.customElements.define('error-span', ErrorSpanElement);
}
